<template>
  <div class="signIn-nameList">
      <el-dialog
        :visible.sync="show"
        width="780px"
        :before-close="handleClose">
        <div class="tabs">
            <span :class="{'active':activeItem}" @click="activeItem = true">已签到 <i>({{signInFinishCount}})</i></span>
            <span :class="{'active':!activeItem}" @click="activeItem = false">未签到 <i>({{signInUndoneCount}})</i></span>
        </div>
        <!-- 数据表单 已签到 -->
        <div class="table-container">
          <el-table
            :data="signInFinish"
            style="width: 100%"
            v-show="activeItem"
            >
            <el-table-column prop="number" label="序号" width="60" align="center" type="index"></el-table-column>
            <el-table-column label="姓名" width="190" prop="uname" align="left">
              <template slot-scope="scope">
                    <p class="row-name">
                      <span class="wes">{{scope.row.uname}}</span> 
                      <i class="signIn-status" v-if="scope.row.status===2||scope.row.status===5">
                        {{signInStatusMap[scope.row.status]}}
                      </i>
                    </p>
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="120" prop="phone" align="center"></el-table-column>
            <!-- <el-table-column label="学校" width="150" prop="shcoolName" align="center">
              <template slot-scope="scope">
                    <p class="row-school wes">{{scope.row.shcoolName}}</p>
              </template>
            </el-table-column> -->
             <el-table-column label="签到地点" prop="signTime" align="center">
              <template slot-scope="scope">
                    <p class="">{{scope.row.placeName}}</p>
              </template>
            </el-table-column>
            <el-table-column label="签到时间" prop="signTime" align="center">
              <template slot-scope="scope">
                    <p class="">{{dayjs(scope.row.signTime).format('YYYY.MM.DD HH:mm')}}</p>
              </template>
            </el-table-column>
          </el-table>
          <!-- 表单数据未签到 -->
          <el-table
            :data="signInUndone"
            style="width: 100%"
            v-show="!activeItem">
            <el-table-column prop="number" label="序号" width="60" align="center" type="index"></el-table-column>
            <el-table-column label="姓名" width="190" prop="uname" align="left">
              <template slot-scope="scope">
                    <p class="row-name">
                      <span class="wes">{{scope.row.uname}}</span> 
                      <i class="signIn-status" v-if="scope.row.status===3">
                        {{signInStatusMap[scope.row.status]}}
                      </i>
                    </p>
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="120" prop="phone" align="center"></el-table-column>
            <el-table-column label="学校" width="150" prop="shcoolName" align="center">
              <template slot-scope="scope">
                    <p class="row-school wes">{{scope.row.shcoolName}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作"  align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  class="operating"
                  :disabled="scope.row.status == 3"
                  @click="handleStatus(scope.$index, scope.row,2)">代签</el-button>
                <el-button
                  size="mini"
                  class="operating"
                  :disabled="scope.row.status == 3"
                  @click="handleStatus(scope.$index, scope.row,3)">请假</el-button>
                <el-button
                  size="mini"
                  class="operating"
                  :disabled="scope.row.status == 3"
                  @click="handleStatus(scope.$index, scope.row,5)">迟到</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <pagination :total="pages.total" 
                    :page.sync="pages.currentPage" 
                    :limit.sync="pages.pageSize"
                    :pageSizes="[5,10,15]"
                    @pagination="handlePageChange">
        </pagination>

        
    </el-dialog>
  </div>
</template>

<script>
export default {
    props:{
         dialogVisible:{
             type:Boolean,
             default:false,
         },
         itemId: {
             type:Number,
         }
    },
       
    data() {
        return {
            show: false,
            activeItem:true,
            pages:{ //分页
              currentPage: 1,
              pageSize:10,
              total:25,
            },
            signInFinishCount:0,
            signInUndoneCount:0,
            signInFinish: [],
            signInUndone: [],
            signInStatusMap:{
              1:'自主签到',
              2:'代签',
              3:'请假',
              5:'迟到',
            }, 
        };
    },
    watch: {
        dialogVisible(newVal,oldVal) {
          this.show = newVal;
        },
        itemId(newVal,oldVal) {
          if(!newVal) return 
          this.getNameList();
          this.signInData();
        },
        activeItem(newVal,oldVal) {
            this.pages.currentPage = 1;
            this.getNameList()
        }
    },
    methods: {
        // 关闭签到名单
        handleClose(done) {
            this.pages.currentPage = 1;
            this.activeItem = true;
            this.$emit('close',{
                dialogVisible: false
            })
        },
        // 翻页
        handlePageChange(data){
            this.pages.currentPage = data.pageIndex
            this.pages.pageSize = data.pageSize
            this.getNameList();
        },
        // 专家修改签到状态
        async handleStatus(index, row, type) {
          let params = {
            id : row.id,
            status : type
          }
          let resData = await this.$Api.Course.editSignInStatus(params);
          // console.log(resData);
          if(resData.data.code !== 200) return;
          this.getNameList();
          this.signInData();
        },
        // 获取名单
        async getNameList() {
          let status = this.activeItem ? 10: 11
          let params = {
            signId : this.itemId,
            status : status,
            page : this.pages.currentPage,
            limit : this.pages.pageSize,
          }
          let resData = await this.$Api.Course.getNameList(params);
          // console.log(resData)
          if(!resData.data) return 
          this.pages.total = resData.data.total;
          status == 10 ? this.signInFinish = resData.data.records : this.signInUndone = resData.data.records;
          
        },
        // 获取签到统计数据
        async signInData() {
          this.signInUndoneCount = 0;
          this.signInFinishCount = 0;
          let params = {
            signId : this.itemId,
          }
          let resData = await this.$Api.Course.getNameListSignStaistics(params);
          // console.log(resData);
          if(JSON.stringify(resData.data) == '[]') return;
          resData.data.map(item=>{
            if(item.userSignStatus == 1 || item.userSignStatus == 2 || item.userSignStatus == 5) this.signInFinishCount += item.count
            if(item.userSignStatus == 3 || item.userSignStatus == 4) this.signInUndoneCount += item.count
          })
          // console.log(this.signInFinishCount)
          // console.log(this.signInUndoneCount)
        },
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
.signIn-nameList {
  /deep/ .el-dialog {
    border-radius: 5px;
  }

  /deep/ .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
  
  /deep/ .el-dialog__header {
    padding: 15px 0 0 0;

    .el-dialog__headerbtn {
      top: 15px;
      right: 27px;
      font-size: 20px;
    }
  }

  .tabs {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(191, 191, 191, .13);
    padding-bottom: 9px;
    padding-left: 52px;
    span {
      margin-right: 33px;
      font-size: 20px;
      color: rgba(153, 153, 153, .74);
      cursor: pointer;
      font-weight: bold;
      i {
        font-weight: 500;
      }

      &.active {
        color: rgba(81, 142, 248, 1);
        position: relative;
        
        &::after {
          content: ' ';
          width: 41px;
          height: 4px;
          position: absolute;
          bottom: -11px;
          left: 50%;
          transform: translateX(-50%);
          background: #518EF8;
          border-radius: 2px;
        }
      }
    }
  }

  .table-container {
    padding: 0 32px 0 30px;
  }

  /deep/ .el-table::before {
    display: none;
  }

  /deep/ .el-table__header tr {
    background: #fff !important;
    color: #999;
  }

  /deep/ .el-table td, 
  /deep/ .el-table th.is-leaf {
    color: #999;
    border-bottom: 0;
  }

  /deep/ .el-table td {
    color: #666;
  }


  .row-name {
    span {
      display: inline-block;
      width: 95px;
      vertical-align: middle;
    }
  }

  .signIn-status {
    margin-left: 23px;
    border-radius: 9px;
    padding: 0 9px;
    line-height: 20px;
    background: #FF685D;
    color: #fff;
    font-size: 12px;
  }

  .row-school {
    width: 130px;
    text-align: left;
  }

  .operating {
    overflow: auto;
    border-radius: 10px;
    border: 1px solid rgba(191, 191, 191, .4);
    padding: 0 11px;
    line-height: 20px;
    color: #666;
    font-size: 12px;

    &:hover {
      background-color: #518EF8;
      color: #fff;
      border: 1px solid #518EF8;
    }

    &.is-disabled {
      color: #C0C4CC;
      
      &:hover {
        background-color: #fff;
        color: #C0C4CC;
        border: 1px solid rgba(191, 191, 191, .4);
      }
    }

  }


  .pagination {
    text-align: center;
    /deep/ .el-pager li.active {
      background-color: #508EF9;
    }
  }

  
}
</style>