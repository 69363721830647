<template>
  <div class="signIn-qrcode">
    <el-dialog
        :visible.sync="show"
        width="319px"
        :before-close="handleClose">
            <div class="qrcode-box">
                <div class="qrcode" id="qrcode" ref="qrcode"></div>
            </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    props:{
         dialogVisible:{
             type:Boolean,
             default:false,
         },
         uuid:{
             type:String,
             default:'',
         }
    },
    data() {
        return {
            show:false,
        };
    },
    watch:{
        dialogVisible(newVal,oldVal) {
            this.show = newVal;
            if(newVal && this.uuid) {
                console.log(this.uuid);
                this.qrcode();
            }
        },
        // uuid(newVal,oldVal) {
        //     console.log(newVal);
        //     if(newVal && this.dialogVisible) {
        //         this.qrcode();
        //     }
        // },
    },
    methods: {
        // 关闭创建
        handleClose(done) {
            this.$refs.qrcode.innerHTML = '';
            this.$emit('close',{
                dialogVisible: false
            })
        },
        qrcode() {
            this.$nextTick(() => {
                let that = this;
                let qrcode = new that.QRCode('qrcode',{
                    width: 156, // 设置宽度，单位像素
                    height: 156, // 设置高度，单位像素
                    text: this.uuid  // 设置二维码内容或跳转地址
                })
            })
        }
    },
    mounted() {
    }
 }
</script>

<style lang="less" scoped>
.signIn-qrcode {
    /deep/ .el-dialog {
        border-radius: 5px;
    }

    /deep/ .el-dialog__header {
    
        .el-dialog__headerbtn {
          top: 15px;
          right: 27px;
          font-size: 20px;
        }
    }

    /deep/ .el-dialog__body {
        padding: 19px 20px 41px;
    }

    .qrcode {
        /deep/ img {
            margin: 0 auto;
        }
    }
}
</style>