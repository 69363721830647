<template>
    <div class="teach_signIn wrapper">

        <proTitle></proTitle>
        <div class="singIn-container">
            <!-- 打卡数据 -->
            <ul class="singIn-data flex-layout">
                <li v-for="(item,index) in signData" :key="index" class="data-item flex-align">
                    <img :src="item.icon" alt="">
                    <div>
                        <span>{{item.name}}</span>
                        <br>
                        <strong>{{item.count}}<i v-if="item.title == 'signRate'">%</i></strong>
                    </div>

                </li>
            </ul>
            <!-- 工具栏 -->
            <el-form :inline="true" :model="toolbar" class="singIn-toolbar flex-align-between">
                <div class="toolbar-left">
                    <el-form-item label>
                        <el-input size="mini" v-model="toolbar.search" @input="filterSignInList" placeholder="请输入关键字"
                            suffix-icon="el-icon-search" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="toolbar.time" placeholder="全部" @change="filterSignInList" size="mini"
                            style="width:100px;">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="一年内" value="1"></el-option>
                            <el-option label="半年内" value="2"></el-option>
                            <el-option label="三月内" value="3"></el-option>
                            <el-option label="一月内" value="4"></el-option>
                            <el-option label="一周内" value="5"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="toolbar-right flex-align-between">
                    <div class="toolbar-item csp" @click="openCreate()">
                        <i class="el-icon-circle-plus-outline"></i>
                        <span>创建签到</span>
                    </div>
                    <!-- <div class="toolbar-item csp">
                            <i class="el-icon-folder-add"></i>
                            <span>批量导入</span>
                        </div> -->
                    <div class="toolbar-item csp" @click="exportExcel()">
                        <i class="el-icon-upload2"></i>
                        <span>导出</span>
                    </div>
                    <div class="toolbar-item csp" @click="deleteSignIn()">
                        <i class="el-icon-delete"></i>
                        <span>删除</span>
                    </div>
                </div>
            </el-form>
            <!-- 签到列表 -->
            <div class="signIn-list-wrapper" v-show="signInList.length > 0">
                <ul class="signIn-list">
                    <li class="list-item flex-align-between" v-for="(item,index) in signInList" :key="index">
                        <div class="list-info">
                            <p>
                                <strong class="info-title wes">{{item.title}}</strong>
                                <i class="signIn-status"
                                    :class="{'signingIn':item.signStatus =='2'}">{{signStatusMap[item.signStatus]}}</i>
                            </p>
                            <div class="info-other">
                                <span>签到时间：{{dayjs(item.startTime).format('YYYY-MM-DD HH:mm')}}-{{dayjs(item.endTime).format('HH:mm')}}</span>
                                <span>签到人数：<i>{{item.signCount}}</i>/{{item.userCount}}</span>
                                <span>创建人：{{item.createUser}}</span>
                            </div>
                        </div>
                        <div class="list-features">
                            <button @click.prevent="openNameList(item)">签到名单</button>
                            <button @click.prevent="openQRcode(item)">二维码</button>
                            <button @click.prevent="openEidt(item)">编辑</button>
                            <el-checkbox v-model="item.checked" @change="changeChecked(item)"></el-checkbox>
                        </div>
                    </li>
                </ul>
                <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                    :pageSizes="[5,10,15]" @pagination="handlePageChange">
                </pagination>
            </div>
            <Empty :show="signInList.length <= 0" :text="'暂无签到'"></Empty>
        </div>

        <signInCreate :dialogVisible="showCreateDialog" @close="colseCreate" :projectId="String(currentProject.id)">
        </signInCreate>
        <nameList :dialogVisible="showNameListDialog" :itemId="activeSignItem.id" @close="colseNameList"></nameList>
        <signInQRcode :dialogVisible="showQRcodeDialog" :uuid="activeSignCode" @close="colseQRcode"></signInQRcode>
        <signInEdit :dialogVisible="showEditDialog" @close="colseEdit" :projectId="String(currentProject.id)"
            :activeItem="activeSignItem"></signInEdit>

    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    import nameList from "./signIn_namelist"
    import signInCreate from "./signIn_create"
    import signInQRcode from "./signIn_qrcode"
    import signInEdit from "./signIn_edit"
    export default {
        components: {
            nameList,
            signInCreate,
            signInQRcode,
            signInEdit
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            })
        },
        data() {
            return {
                signData: [{
                        title: 'allSign',
                        name: '已有签到',
                        count: '0',
                        icon: require("@/assets/images/teach/signin-number.png")
                    },
                    {
                        title: 'finishSign',
                        name: '已完成签到',
                        count: '0',
                        icon: require("@/assets/images/teach/signin-finish.png")
                    },
                    {
                        title: 'signUserCount',
                        name: '签到人次',
                        count: '0',
                        icon: require("@/assets/images/teach/signin-people.png")
                    },
                    {
                        title: 'signRate',
                        name: '签到率',
                        count: '0%',
                        icon: require("@/assets/images/teach/signin-rate.png")
                    }
                ],
                toolbar: { //查询项绑定value
                    search: "", //搜索
                    time: "", //时间
                },
                signInList: [],
                signStatusMap: {
                    1: '未开始',
                    2: '进行中',
                    3: '已结束',
                },
                activeSignItem: {},
                activeSignCode: '',
                checkedItem: [],
                pages: { //分页
                    currentPage: 1,
                    pageSize: 5,
                    total: 25,
                },
                showNameListDialog: false,
                showCreateDialog: false,
                showQRcodeDialog: false,
                showEditDialog: false,
            };
        },
        watch: {
            '$store.state.user.currentProject.id': {
                handler(newVal, oldVal) {
                    if (!newVal) return;
                    this.pages.currentPage = 1;
                    this.getSignInList();
                    this.getSignStatistics();
                }
            }
        },
        methods: {
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getSignInList();
            },
            // 打开签到名单
            openNameList(item) {
                this.showNameListDialog = true;
                this.activeSignItem = item;
            },
            // 关闭签到名单
            colseNameList(data) {
                this.showNameListDialog = data.dialogVisible;
                this.getSignInList();
            },
            // 打开二维码
            openQRcode(item) {
                this.showQRcodeDialog = true;
                this.activeSignCode = item.code;
            },
            // 关闭二维码
            colseQRcode(data) {
                this.showQRcodeDialog = data.dialogVisible;
            },
            // 打开编辑
            openEidt(item) {
                if (item.signStatus != 3) {
                    this.showEditDialog = true;
                    this.activeSignItem = item;
                } else {
                    this.$message('该签到已完成，不可编辑');
                }
            },
            // 关闭编辑
            colseEdit(data) {
                // console.log(data);
                this.showEditDialog = data.dialogVisible;
                if (data.isSuccess) {
                    this.getSignInList();
                }

            },
            // 打开创建
            openCreate(item) {
                this.showCreateDialog = true;
            },
            // 关闭创建
            colseCreate(data) {
                // console.log(data);
                if (data.isSuccess) {
                    this.getSignInList();
                    this.getSignStatistics();
                }
                this.showCreateDialog = data.dialogVisible;
            },
            // 导出签到 
            async exportExcel() {
                if (this.checkedItem.length == 0) {
                    this.$message.error('请选择需要导出的签到记录');
                    return
                }
                if (this.checkedItem.length >= 2) {
                    this.$message.error('每次只能导出单条签到记录');
                    return
                }
                // let token = sessionStorage.getItem('token');
                let token = this.$store.state.user.token;
                let id = this.checkedItem[0];
                window.open(`${this.commonbaseURL}/course/sign/exportExcel/${id}?token=${token}`);
            },
            // 批量删除签到
            deleteSignIn() {
                if (this.checkedItem.length == 0) {
                    this.$message.error('请选择需要删除的签到记录');
                    return
                }
                this.$confirm(`是否删除选中的${this.checkedItem.length}条签到？`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        let params = {
                            ids: this.checkedItem.join(',')
                        }
                        // console.log(params);
                        this.$Api.Course.deleteSignIn(params).then((resData) => {
                            // console.log(resData);
                            if (resData.code == 200) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success"
                                });
                                this.checkedItem = [];
                                this.pages.currentPage = 1;
                                this.getSignInList();
                                this.getSignStatistics();
                            } else {
                                this.$message.error(resData.msg);
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            changeChecked(item) {
                if (item.checked) this.checkedItem.push(item.id);
                if (!item.checked) this.checkedItem.remove(item.id);
            },
            // 条件筛选
            filterSignInList() {
                this.pages.currentPage = 1;
                this.getSignInList();
            },
            // 获取签到列表
            async getSignInList() {
                let params = {
                    projectId: this.currentProject.id,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                this.toolbar.search ? params.key = this.toolbar.search : '';
                this.toolbar.time ? params.timeType = this.toolbar.time : '';
                let resData = await this.$Api.Course.getSignInList(params);
                // console.log(resData);
                this.pages.total = resData.data.total;
                this.signInList = resData.data.records;

                this.signInList.map(item => {
                    Vue.set(item, 'checked', false);
                    this.checkedItem.map(each => {
                        if (item.id == each) {
                            console.log(11);
                            Vue.set(item, 'checked', true);
                        }
                    })
                })
            },
            // 获取签到统计数据
            async getSignStatistics() {
                let params = {
                    projectId: Number(this.currentProject.id)
                }
                let resData = await this.$Api.Course.getSignStatistics(params);
                this.signData.map(item => {
                    item.count = resData.data[item.title]
                    if (item.title === 'signRate') {
                        item.count = (resData.data[item.title] * 100).toFixed(2)
                    }
                })
            }
        },
        mounted() {
            this.getSignInList();
            this.getSignStatistics();

        },
    }
</script>

<style lang="less" scoped>
    .teach_signIn {
        .singIn-container {
            border-radius: 10px;
            background-color: #fff;
            // padding: 24px 42px 12px 34px;
        }

        .singIn-data {
            .data-item {
                width: 149px;
                margin-right: 16px;
                border: 1px solid #E7E7E7;
                border-radius: 5px;
                padding: 16px 20px;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                img {
                    margin-right: 14px
                }

                strong {
                    font-size: 16px;
                }
            }
        }

        .singIn-toolbar {
            margin: 26px 0 14px;

            .toolbar-item {
                margin-right: 20px;

                &:last-child {
                    margin-right: 3px;
                }

                i {
                    margin-right: 8px;
                    font-size: 19px;
                    vertical-align: text-bottom;
                }

                span {
                    font-size: 14px;
                }
            }
        }

        /deep/ .el-form--inline {
            .el-form-item {
                margin: 0 23px 0 0;
                vertical-align: middle;
            }

            .el-input--mini .el-input__inner {
                border-radius: 12px;
            }
        }

        .list-item {
            margin-bottom: 22px;
            border: 1px solid #F2F2F2;
            border-radius: 10px;
            padding: 12px 20px 8px 15px;

            .info-title {
                display: inline-block;
                max-width: 320px;
                font-size: 15px;
            }

            .signIn-status {
                margin-left: 35px;
                border: 1px solid #999999;
                border-radius: 8px;
                padding: 0 10px;
                color: #999999;
                font-size: 12px;

                &.signingIn {
                    border: 1px solid #508EF9;
                    color: #508EF9;
                }
            }

            .info-other {
                margin-top: 12px;
                font-size: 12px;
                color: #999;

                span:first-child {
                    margin-right: 20px;
                }

                span:nth-child(2) {
                    margin-right: 15px;

                    i {
                        color: #FE6725;
                    }
                }
            }

            .list-features {
                flex-shrink: 0;

                button {
                    margin-right: 22px;
                    cursor: pointer;
                    color: #666;
                    font-size: 12px;
                }

                /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #fff;
                    border-color: #508EF9;
                }

                /deep/ .el-checkbox__inner::after {
                    border: 2px solid #508EF9;
                    border-left: 0;
                    border-top: 0;
                    height: 10px;
                    left: 4px;
                    top: -1px;
                }
            }
        }


        .pagination {
            text-align: center;

            /deep/ .el-pagination .el-pager .active {
                background-color: #508EF9;
            }
        }

        @media screen and (min-width: 1250px) {
            .singIn-container {
                // padding: 30px 56px 12px 50px;
            }

            .singIn-data .data-item {
                width: 300px;
                margin-right: 44px;
                padding: 22px 26px;

                img {
                    margin-right: 21px
                }

                strong {
                    font-size: 19px;
                }
            }

            .singIn-toolbar {
                margin: 32px 0 20px;
            }

            .list-item {
                margin-bottom: 28px;
                padding: 16px 30px 12px 20px;

                .info-other {

                    span:first-child {
                        margin-right: 48px;
                    }

                    span:nth-child(2) {
                        margin-right: 42px;
                    }
                }
            }

            .list-features {
                button {
                    margin-right: 35px;
                    cursor: pointer;
                    color: #666;
                    font-size: 14px;
                }

                /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #fff;
                    border-color: #508EF9;
                }

                /deep/ .el-checkbox__inner::after {
                    border: 2px solid #508EF9;
                    border-left: 0;
                    border-top: 0;
                    height: 10px;
                    left: 4px;
                    top: -1px;
                }
            }
        }
    }
</style>