<template>
  <div class="signIn-create">
    <el-dialog
        title="创建签到"
        :visible.sync="show"
        width="600px"
        :before-close="handleClose">
            <el-form ref="createForm" :model="form" label-width="120px" :rules="rules">

              <el-form-item label="签到标题：" required prop="title">
                <el-input v-model.trim="form.title" size="mini" style="width:220px;" placeholder="请输入标题" 
                maxlength="30"
                show-word-limit></el-input>
              </el-form-item>
              
              <el-form-item label="签到时间：" required class="form-item-time">
                <el-col :span="7">
                  <el-form-item prop="date">
                    <el-date-picker type="date"
                                    class="signIn-date"
                                    placeholder="选择日期" 
                                    v-model="form.date" 
                                    size="mini" 
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    @change="changeDate"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                   <el-form-item prop="timeStart">
                   <el-time-picker v-model="form.timeStart"  
                                   placeholder="开始时间" 
                                   size="mini" 
                                   class="signIn-time" 
                                   value-format="HH:mm:ss"
                                   :disabled="startTimeDisabled"
                                   :picker-options="{selectableRange:pickerSatrtTime()}"
                                   @change="changeStartTime"></el-time-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2"><span class="link">至</span></el-col>
                <el-col :span="7">
                  <el-form-item prop="timeEnd">
                  <el-time-picker v-model="form.timeEnd"  
                                placeholder="结束时间" 
                                size="mini" 
                                class="signIn-time"
                                :disabled="endTimeDisabled"
                                value-format="HH:mm:ss"
                                :picker-options="{selectableRange:pickerEndTime()}"></el-time-picker>
                  </el-form-item>
                </el-col>
              </el-form-item>

              <el-form-item label="签到绑定：" required>
                <el-radio-group v-model="form.bindType" @change="changeBindType">
                  <el-radio :label="1">课程</el-radio>
                  <el-radio :label="2">活动</el-radio>
                  <el-radio :label="3">项目</el-radio>
                </el-radio-group>
              </el-form-item>
              
              <el-form-item label="选择课程：" ref="bindCourse"  required v-show="form.bindType === 1" size="mini" prop="bindId">
                <el-select v-model="form.bindId" placeholder="请选择课程">
                   <el-option v-for="item in courseList" :key="item.value" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>

               <el-form-item label="选择活动：" ref="bindActivity"  required v-show="form.bindType === 2" size="mini" prop="bindId">
                <el-select v-model="form.bindId" placeholder="请选择活动">
                   <el-option v-for="item in activityList" :key="item.value" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
        

              <el-form-item label="绑定项目：" :required="bindProRequired" v-show="form.bindType === 3">
                <el-col :span="11">
                  <el-form-item size="mini" :required="bindProRequired" prop="projectPeriodId" ref="bindPeriod" class="form-item-period">
                   <el-select v-model="form.projectPeriodId" placeholder="请选择" @change="periodChange">
                    <el-option v-for="item in periodList" :key="item.value" :label="item.name" :value="item.id"></el-option>
                   </el-select>
                 </el-form-item>
                </el-col>
                <el-col :span="11">
                   <el-form-item size="mini" :required="bindProRequired"  prop="bindId" ref="bindProId" class="form-item-project">
                     <el-cascader :options="projectList" :props="{ checkStrictly: true, value: 'id', label: 'name'}"
                      clearable v-model="form.bindId" @change="projectChange" style="margin-left:20px"></el-cascader>
                   </el-form-item>
                </el-col>

              </el-form-item>
               <!-- emitPath:false -->
              <el-form-item label="用户组：" :required="bindProRequired" prop="userGroupValue" ref="bindUserGroup" v-show="form.bindType === 3">
                <el-cascader :options="userGroup" :props="{ multiple: true, value: 'id'}" clearable filterable
                 v-model="form.userGroupValue" ref="cascader" @change="userGroupChange" size="mini" collapse-tags	>
                </el-cascader>
              </el-form-item>

              <el-form-item class="btns-box">
                <el-button class="cancel btn" @click="handleClose">取消</el-button>
                <el-button  class="submit btn" type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
            </el-form>         
    </el-dialog>
  </div>
</template>

<script>
export default {
    props:{
      dialogVisible:{
        type:Boolean,
        default:false,
      },
      projectId:{
        type:String,
        default:''
      }
    },
    data() {
        return {
            show: false, //显示该模块
            isCreateSuccess:false, //是否创建成功
            bindProRequired:false, //绑定项目required状态
            startTimeDisabled:true,
            endTimeDisabled:true,
            courseList:[], //课程列表
            activityList:[], //活动列表
            periodList:[], //期次列表
            projectList:[], //期次下的项目列表
            userGroup: [], //用户组
            userGroupValue: '', //用户组选中的id的值
            userGroupArray: [], //用户组id数组(接口传参需要)
            //验证表单
            form: { 
              title: '',
              date: '',
              timeStart: '',
              timeEnd: '',
              bindType: 1,
              bindId:'',
              projectPeriodId:'',
              userGroupValue:[],
            },
            // 提交表单
            submitForm:{
              title: '',
              endTime:'',
              startTime:'',
              bindType:'',
              bindId:'',
            },
            pickerOptions: { //今天以前（不含今天）禁用
                disabledDate(time) {
                  return time.getTime() < Date.now() - 8.64e7;
                }
            },
            pickerSatrtTime:()=> {
                let time = `${this.form.date} ${this.form.timeStart}`
                if(this.dayjs(time).unix() > this.dayjs().unix()) {
                    return `00:00:00 - 23:59:59`;
                }
                let nowTime = this.dayjs().format('HH:mm:ss');
                return `${nowTime} - 23:59:59`;
            },
            pickerEndTime:()=> {
                if(!this.form.timeStart) return `00:00:00 - 23:59:59`;
                let nowTime = this.dayjs().format('HH:mm:ss');
                return `${this.form.timeStart} - 23:59:59`;
            },
            // pickerTime:()=> { // 当前时间以前禁用
            //     if(this.form.timeStart) {
            //       return `${this.form.timeStart} - 23:59:59`;
            //     }
            //     let nowTime = this.dayjs().format('HH:mm:ss');
            //     return `${nowTime} - 23:59:59`;
            // },
            //表单验证规则
            rules: {
              title: { required: true, message: "请输入标题", trigger: "blur" },
              date: { required: true, message: "请选择日期", trigger: "blur" },
              timeStart:{ required: true, message: "请选择开始时间", trigger: "blur" },
              timeEnd: { required: true, message: "请选择结束时间", trigger: "blur" },
              bindId: { required: true, message: "请选择", trigger: "change" },
              projectPeriodId: { required: false, message: "请选择项目分期", trigger: "change"},
              userGroupValue: { required: false, message: "请选择用户组", trigger: "change",type:'array'},
            },
        };
    },
    watch:{
        dialogVisible(newVal,oldVal) {
            this.show = newVal;
            Vue.set(this.form,'bindType',1);
        },
        projectId(newVal,oldVal) {
          if(newVal === '0' || !newVal) return;
          this.getCourseList();
        }
    },
    computed:{
      formatTime:function() {
        this.form.startTime = `${this.form.date} ${this.form.timeStart}` 
        this.form.endTime = `${this.form.date} ${this.form.timeEnd}` 
      }
    },
    methods:{
        // 改变日期
        changeDate(val) {
          if(val) {
            this.startTimeDisabled = false;
            this.endTimeDisabled = true;
          } else {
            this.startTimeDisabled = true;
            this.endTimeDisabled = true;
          }
          this.form.timeStart = "";
          this.form.timeEnd = "";
        },
        // 改变开始时间
        changeStartTime(val) {
          if(val) {
            this.endTimeDisabled = false;
            return;
          }
          this.form.timeEnd = "";
          this.endTimeDisabled = true;

        },
        // 拷贝需要提交的表单项
        copyForm() {
            this.formatTime;
            if(this.form.bindType === 3) {
              this.submitForm.projectPeriodId = '';
              this.submitForm.userGroupArray = '';
              this.submitForm.userGroupId = '';
            }
            for (const key in this.submitForm) {
              this.submitForm[key] = this.form[key];
            }
            this.submitForm.projectId = this.projectId;
            
            // console.log('submitForm',this.submitForm);
        },
        // 关闭创建
        handleClose(done) {
            this.$emit('close',{
                dialogVisible: false,
                isSuccess:this.isCreateSuccess
            })
            this.$refs['createForm'].resetFields();
            this.isCreateSuccess = false;
            this.bindProRequired = false;
        },
        // 表单验证
        onSubmit() {
          // console.log('from',this.form)
          // console.log(this.rules);
          this.$refs['createForm'].validate((valid)=>{
            // console.log('valid',valid);
            if(valid) {
              this.copyForm();
              this.commitForm();
            } else {
              console.log('error')
            }
          })
        },
        // 提交表单
        async commitForm(){
          let resData = await this.$Api.Course.createSignIn(this.submitForm);
          if (resData.code === 200) {
            DoUpElement.notify(this,'success',"创建成功")
            this.isCreateSuccess = true;
            this.handleClose();
          } else {
             DoUpElement.notify(this,'error',resData.msg)
          }
        },
        // 切换绑定模式
        changeBindType(val) {
          let that = this;
          let map = {
            1:{
              name:['bindCourse'],
              bindProRequired:false,
              request:that.getCourseList(),
            },
            2:{
              name:['bindActivity'],
              bindProRequired:false,
              request:that.getActivityList(),
            },
            3:{
              name:['bindPeriod','bindUserGroup','bindProId'],
              bindProRequired:true,
              request:that.getPeriod(),
            },
          }

          this.bindProRequired = this.rules.projectPeriodId.required = this.rules.userGroupValue.required = map[val].bindProRequired;
          map[val].request;
          map[val].name.map((item)=>{
            that.$refs[item].resetField();
          })
        },
        //获取绑定课程列表
        getCourseList() {
          this.$Api.Form.getCourse(this.projectId)
            .then(res => {
              this.courseList = res.data
            })
            .catch(err => {
              console.log(err);
            });
        },
         //获取绑定活动列表
        getActivityList() {
          this.$Api.Form.getActive(this.projectId)
            .then(res => {
              this.activityList = res.data;
            })
            .catch(err => {
              console.log(err);
            });
        },
        //获取绑定项目分期
        getPeriod() {
          return this.$Api.Form.getPeriod(this.projectId)
            .then(res => {
              // console.log(res)
              this.periodList = res.data;
              return Promise.resolve(this.periodList)
            })
            .catch(err => {
              console.log(err);
              return Promise.reject(err)
            });
        },
        //绑定项目期次变化 获取对应 cascader
        periodChange(val) {
          let arr = this.periodList.filter(item => {
            return item.id == val
          })
          this.projectList = arr[0].children
        },
        //根据项目期次获取对应 cascader 获取对应的用户组
        projectChange(val) {
          this.form.bindId = val[val.length - 1];
          this.$Api.Form.getUserGroup(val[val.length - 1])
            .then(res => {
              this.userGroup = res.data;
            })
            .catch(err => console.log(err));
        },
        //监听用户组变化(获取用户组id数组用于传参)
        userGroupChange(val) {
          this.form.userGroupArray = JSON.stringify(val);
          let userGroups = this.$refs["cascader"].getCheckedNodes();
          let userGroupArr = [];
          userGroups.map(item => {
            userGroupArr.push(item.value);
          });
          this.form.userGroupId = userGroupArr.join(',');
        },
    },
    mounted() {},
}
</script>

<style lang="less" scoped>
.signIn-create {
    /deep/ .el-dialog {
        border-radius: 5px;
    }

    /deep/ .el-dialog__header {
        padding: 0;
        text-align: center;
        background-image: url("~@/assets/images/teach/create-title.png");
        background-size: 100% 100%;
        
        .el-dialog__title {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            line-height: 59px;
        }

        .el-dialog__headerbtn {
          top: 15px;
          right: 27px;
          font-size: 20px;
            .el-dialog__close {
                color: #fff;
            }
        }
    }

    /deep/ .el-form-item {
        margin-bottom: 15px;
    }

    /deep/ .el-input--mini .el-input__inner {
        height: 30px;
        line-height: 30px;
    }

    /deep/ .el-range-editor--mini.el-input__inner {
        height: 30px;
    }

    .form-item-time{
      /deep/ .el-form-item {
          margin-bottom: 0;
      }
    }

    .form-item-period,
    .form-item-project {
      margin-bottom: 0;
    }

    .signIn-date {
        margin-right: 10px;
        width: 140px;

        /deep/ .el-input__inner {
            border: 0;
            font-size: 15px;
            color: #666666;
        }

        /deep/ .el-icon-date {
            font-size: 15px;
        }
    }

    .link {
        margin-right: 20px;
        font-size: 15px;
        color: #999999;
    }

    .signIn-time {
        width: 125px;
        border: 0px;

        /deep/ .el-input__inner,
        .el-date-editor.el-input__inner {
            border: 0;
            font-size: 15px;
            color: #666666;
        }

        /deep/ .el-input__prefix {
            font-size: 15px;
        }
    }

    .btns-box {
        margin-top: 37px;
        
        .btn {
            padding: 0 39px;
            line-height: 32px;
            border: 1px solid #508EF9;
            border-radius: 5px;
            color: #2774EE;
        }

        .submit {
            margin-left: 96px;
            color: #fff;
            background-color: #508EF9;
        }
    }   
}

</style>